"use client";

import React, { type FC, type PropsWithChildren, createContext, useContext, useState } from "react";
import { type User } from "~/components/domain/user/model/user-model";
import { type Organization } from "~/components/domain/organization/model/organization-model";
interface Permissions {
  hasAdminPermissions: boolean;
  hasWritePermissions: boolean;
}

// Define context
interface SessionContextType {
  user: User | null;
  organization: Organization | null;
  permissions: Permissions | null;
}

// Create the context with default values
const SessionContext = createContext<SessionContextType | undefined>(undefined);
export interface SessionProviderProps extends PropsWithChildren {
  user?: User | null | undefined;
  organization?: Organization | null | undefined;
  permissions?: Permissions | null | undefined;
}

// Provider component
export const SessionProvider: FC<SessionProviderProps> = ({
  children,
  ...props
}) => {
  const [user, _setSession] = useState<User | null>(props.user ?? null);
  const [organization, _setOrganization] = useState<Organization | null>(props.organization ?? null);
  const [permissions, _setPermissions] = useState<Permissions | null>(props.permissions ?? null);
  return <SessionContext.Provider value={{
    user,
    organization,
    permissions
  }} data-sentry-element="unknown" data-sentry-component="SessionProvider" data-sentry-source-file="session-provider.tsx">
      {children}
    </SessionContext.Provider>;
};

// Custom hook to use SessionContext
export const useSession = (): SessionContextType => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within an SessionProvider");
  }
  return context;
};